
import { FormValidations } from "@/mixins/form-validations";
import { CustomDate } from "@/mixins/custom-date";
import VueCountryCode from "vue-country-code";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import FilePicker from "@/components/Input/FilePicker.vue";
import LanguagePicker from "@/components/Input/LanguagePicker.vue";
import Captcha from "@/components/Input/Captcha.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    VueCountryCode,
    FilePicker,
    LanguagePicker,
    Captcha,
  },
})
export default class SignUp extends mixins(
  Navigation,
  FormValidations,
  CustomDate,
  StyleCheck
) {
  $refs!: {
    signUpForm: HTMLFormElement;
  };
  phoneCountry: { dialCode: string; iso2: string } = { dialCode: "", iso2: "" };
  formatedPhone = "";
  valid = false;
  loading = false;
  showPassword = false;
  showPasswordCheck = false;
  showErrorPassword = true;
  subscribeToNewsletter = true;

  user = {
    identification: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    alias: "",
    email: "",
    password: "",
    address: {
      primary_line: "",
      secondary_line: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
    language: undefined,
  };

  kyc = {
    description: "",
    ip_address: "",
  };

  verifyUserPassword = "";
  minLengthPassword = process.env.VUE_APP_MIN_LENGTH_PASSWORD;
  userValidations = {
    alias: false,
    loadingAlias: false,
    email: false,
    loadingEmail: false,
  };
  passwordValidations = {
    minLength: false,
    minSpecial: false,
    minNumber: false,
    minCapitalLetter: false,
    noEmptySpaces: false,
    equals: false,
  };

  hcaptcha_site_key = process.env.VUE_APP_HCAPTCHA_SITE_KEY;
  hcaptcha_done = false;

  filePreview1 = "";
  kycFile1: any = null;
  filePreview2 = "";
  kycFile2: any = null;
  filePreview3 = "";
  kycFile3: any = null;

  private selectedCountry(country: any): void {
    this.phoneCountry = country;
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      (this.user.phone == null ? "" : this.user.phone) +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }

  private checkUserPassword(): boolean {
    if (
      this.passwordValidations.minLength &&
      this.passwordValidations.minSpecial &&
      this.passwordValidations.minNumber &&
      this.passwordValidations.minCapitalLetter &&
      this.passwordValidations.noEmptySpaces &&
      this.passwordValidations.equals
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  @Watch("language")
  resetValidations() {
    this.resetFormValidations([this.$refs.signUpForm]);
  }

  @Watch("user.phone")
  setUserPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      clearPhone +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }

  @Watch("user.password")
  @Watch("verifyUserPassword")
  verifyEqualsPasswords(): void {
    if (
      this.user.password == this.verifyUserPassword &&
      this.user.password != "" &&
      this.verifyUserPassword != "" &&
      this.user.password != null &&
      this.verifyUserPassword != null
    ) {
      this.passwordValidations.equals = true;
    } else {
      this.passwordValidations.equals = false;
    }
  }

  @Watch("user.password")
  verifyMinLength(value: string): void {
    if (value != null) {
      if (value.length >= process.env.VUE_APP_MIN_LENGTH_PASSWORD) {
        this.passwordValidations.minLength = true;
      } else {
        this.passwordValidations.minLength = false;
      }
    }
  }

  @Watch("user.password")
  verifyMinCapitalLetter(value: string): void {
    if (/[A-Z]/.test(value)) {
      this.passwordValidations.minCapitalLetter = true;
    } else {
      this.passwordValidations.minCapitalLetter = false;
    }
  }

  @Watch("user.password")
  verifyMinNumber(value: string): void {
    if (/[0-9]/.test(value)) {
      this.passwordValidations.minNumber = true;
    } else {
      this.passwordValidations.minNumber = false;
    }
  }

  @Watch("user.password")
  verifyNoEmptySpaces(value: string): void {
    if (/[ \t\r\n\f]/.test(value)) {
      this.passwordValidations.noEmptySpaces = false;
    } else {
      this.passwordValidations.noEmptySpaces = value != null ? true : false;
    }
  }

  @Watch("user.password")
  verifyMinSpecial(value: string): void {
    if (/[#$%&/=*!¡?¿]/.test(value)) {
      this.passwordValidations.minSpecial = true;
    } else {
      this.passwordValidations.minSpecial = false;
    }
  }

  @Watch("user.password")
  @Watch("verifyUserPassword")
  hideErrorPassword(): void {
    if (this.checkUserPassword()) {
      this.showErrorPassword = false;
    } else {
      this.showErrorPassword = true;
    }
  }

  aliasTimer = 0;
  @Watch("user.alias")
  async verifyAlias(value: string) {
    if (this.user.alias) {
      this.userValidations.loadingAlias = true;
      clearTimeout(this.aliasTimer);
      this.aliasTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: "",
            alias: value,
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.aliasExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.userValidations.alias = exists;
        this.userValidations.loadingAlias = false;
      }, 500);
    }
  }

  emailTimer = 0;
  @Watch("user.email")
  async verifyEmail(value: string) {
    const emailRegex = new RegExp(
      `^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$`
    );

    if (this.user.email && emailRegex.test(value as string)) {
      this.userValidations.loadingEmail = true;
      clearTimeout(this.emailTimer);
      this.emailTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: value,
            alias: "",
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.emailExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.userValidations.email = exists;
        this.userValidations.loadingEmail = false;
      }, 500);
    }
  }

  private async submit() {
    this.loading = true;
    await this.verifyAlias(this.user.alias);
    await this.verifyEmail(this.user.email);
    if (
      !this.userValidations.alias &&
      !this.userValidations.email &&
      this.$refs.signUpForm.validate() &&
      this.hcaptcha_done
    ) {
      let user = { ...this.user };
      user.phone = this.formatedPhone;
      await this.$store
        .dispatch("authentication/clientSignUp", {
          user: user,
          kyc: this.requireKYC,
        })
        .then(async (response) => {
          const user_uuid = response.data.id;
          if (this.subscribeToNewsletter) {
            const newsletter: any = {
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              email: this.user.email,
              date: this.currentDate(),
              language: this.user.language,
            };
            await this.$store
              .dispatch("newsletter/subscribe", { newsletter: newsletter })
              .then(async (response) => {
                if (response.new) {
                  const Success: Notification = {
                    message: this.$tc("Newsletter.NDsuccess"),
                    timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
                    top: true,
                    bottom: false,
                    left: false,
                    right: false,
                    currentPath: this.$route.fullPath,
                    error: false,
                  };

                  this.$store.dispatch(
                    "notifications/showNotification",
                    Success
                  );
                }
              })
              .catch(() => {
                const Error: Notification = {
                  message: this.$tc("Newsletter.NDerror"),
                  timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                  top: true,
                  bottom: false,
                  left: false,
                  right: false,
                  currentPath: this.$route.fullPath,
                  error: true,
                };

                this.$store.dispatch("notifications/showNotification", Error);
              });
          }

          if (this.requireKYC) {
            await this.$store
              .dispatch("kyc/createUserKYC", {
                description: this.kyc.description,
                user_id: user_uuid,
              })
              .then(async (response) => {
                const kycFiles: any[] = [];
                const payloadFiles = new FormData();

                kycFiles.push(this.kycFile1);
                kycFiles.push(this.kycFile2);
                kycFiles.push(this.kycFile3);

                kycFiles.forEach((file) => {
                  payloadFiles.append(
                    this.$constants.FILE_CATEGORY.KYC,
                    file as File
                  );
                });

                await this.$store
                  .dispatch("kyc/addKYCFiles", {
                    files: payloadFiles,
                    kyc_id: response.id,
                    user_id: user_uuid,
                  })
                  .then(() => {
                    const Success: Notification = {
                      message: this.$tc("Views.su-7"),
                      timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
                      top: true,
                      bottom: false,
                      left: false,
                      right: false,
                      currentPath: this.$route.fullPath,
                      error: false,
                    };

                    this.$store.dispatch(
                      "notifications/showNotification",
                      Success
                    );
                  })
                  .catch(() => {
                    const Error: Notification = {
                      message: this.$tc("Views.su-8"),
                      timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                      top: true,
                      bottom: false,
                      left: false,
                      right: false,
                      currentPath: this.$route.fullPath,
                      error: true,
                    };

                    this.$store.dispatch(
                      "notifications/showNotification",
                      Error
                    );
                  });
              })
              .catch(() => {
                const Error: Notification = {
                  message: this.$tc("Views.su-9"),
                  timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                  top: true,
                  bottom: false,
                  left: false,
                  right: false,
                  currentPath: this.$route.fullPath,
                  error: true,
                };

                this.$store.dispatch("notifications/showNotification", Error);
              });
          }

          if (this.requireKYC) {
            this.navigate("/kyc-validation-process");
          } else {
            this.navigate("/email-sent");
          }
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("SignUp.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      if (this.hcaptcha_done == false) {
        const Error: Notification = {
          message: this.$tc("Views.hCaptchaError1"),
          timeout: "60000",
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      }
    }
  }

  private get functionalities(): any[] {
    return this.$store.getters["functionalities/getFunctionalities"];
  }

  private get requireKYC(): boolean {
    let requireKYC = false;
    this.functionalities.forEach((functionality) => {
      if (
        functionality.name == this.$constants.FUNCTIONALITY.KYC &&
        functionality.status.name == this.$constants.STATUS.ACTIVE
      ) {
        requireKYC = true;
      } else if (
        functionality.name == this.$constants.FUNCTIONALITY.KYC &&
        functionality.status.name == this.$constants.STATUS.INACTIVE
      ) {
        requireKYC = false;
      }
    });
    return requireKYC;
  }

  @Watch("kycFile1")
  private previewFile1(file: File): void {
    if (file != null) {
      this.filePreview1 = URL.createObjectURL(file);
    } else {
      this.filePreview1 = "";
      this.kycFile1 = null;
    }
  }

  @Watch("kycFile2")
  private previewFile2(file: File): void {
    if (file != null) {
      this.filePreview2 = URL.createObjectURL(file);
    } else {
      this.filePreview2 = "";
      this.kycFile2 = null;
    }
  }

  @Watch("kycFile3")
  private previewFile3(file: File): void {
    if (file != null) {
      this.filePreview3 = URL.createObjectURL(file);
    } else {
      this.filePreview3 = "";
      this.kycFile3 = null;
    }
  }
}
